import { minDeadlineBuilding } from "./pages/components/JKCard/JKCard";
import { JK } from "./redux/interfaces";

export const cityNormalizer = (city: string) => {
  const norms = {
    msk: "Москва",
    krasnodar: "Краснодар",
    spb: "Санкт-Петербург",
    rostov: "Ростов",
    ekb: "Екатеринбург",
    kzn: "Казань",
    nsk: "Новосибирск",
  };
  return (norms as any)[city];
};
export const getFormattedDate = (dateString: string): string => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("ru-RU", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);
};

export function formatPrice(price: number) {
  const num = price;

  if (isNaN(num)) {
    return "";
  }
  let formattedPrice;
  if (num >= 1_000_000) {
    // Format as millions
    formattedPrice = (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + " млн";
  } else if (num >= 1_000) {
    // Format as thousands
    formattedPrice = (num / 1_000).toFixed(1).replace(/\.0$/, "") + " тыс";
  } else {
    // Format as is
    formattedPrice = num.toFixed(0);
  }

  return formattedPrice;
}

export function getCountedWord(
  count: number,
  baseWord: string,
  expectedEnds: [string, string, string] = ["а", "ы", ""]
) {
  const nums = String(count).split("");
  const lastNum = Number(nums[nums.length - 1]);
  if (lastNum === 1) {
    return `${baseWord}${expectedEnds[0]}`;
  } else if (
    lastNum >= 2 &&
    lastNum <= 4 &&
    (nums.length > 2 || nums.length === 1)
  ) {
    return `${baseWord}${expectedEnds[1]}`;
  } else {
    return `${baseWord}${expectedEnds[2]}`;
  }
}
export const formatPriceSpaces = (price: number): string => {
  if (!price) return "";
  return price
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];
};
export const formatAddressHtml = (block: JK) => {
  const image_src = block["images"][0]["src"];
  return `<div class="jk-map-card" style="width: 192px; padding: 8px; background: rgba(30, 30, 30, 1); color: white;">
        <h2 style="font-size: 14px; font-weight: 700; margin-bottom: 8px;">${
          block["name"]
        }</h2>
        <div class="img-box">
            <img src="${image_src}" alt="${
    block["name"]
  }" style="border-radius: 5px; width: 100%; height: auto;">
            <div class="text-box">
                <p style="font-size: 12px; font-weight: 500;">${minDeadlineBuilding(
                  block
                )}</p>
                <p style="font-size: 12px; font-weight: 600; margin-bottom: 8px;">от ${formatPriceSpaces(
                  block["min_price"]
                )} ₽</p>
            </div>
        </div>
       <a href="/blocks/${
         block.id
       }/"> <button style="height: 28px; background-color: var(--bg-1); color: white; border: none; border-radius: 5px; cursor: pointer; width: 100%; font-size: 12px;">Посмотреть</button></a>
    </div>`;
};
