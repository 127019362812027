import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from "react";

export type Address = {
  html: string;
  address: string;
};

type YandexMapProps = {
  addresses: Address[];
  style?: CSSProperties;
  children?: ReactNode;
  centerCoords: [number, number];
  iconSettings?: any;
};



const YandexMap = ({
  addresses,
  style,
  children,
  centerCoords,
  iconSettings,
}: YandexMapProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [ymaps, setYmaps] = useState<any | null>(null);

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/2.1/?apikey=172fe03d-8bc0-481b-9aec-1e6eceb6e88b&lang=ru_RU`;
      script.async = true;
      script.onload = () => setYmaps((window as any).ymaps);
      document.body.appendChild(script);
    };

    if (!(window as any).ymaps) {
      loadScript();
    } else {
      setYmaps((window as any).ymaps);
    }
  }, []);

  useEffect(() => {
    if (ymaps) {
      ymaps.ready(initMap);
    }
  }, [ymaps]);

  const initMap = async () => {
    if (!mapRef.current || !ymaps) return;

    const map = new ymaps.Map(mapRef.current, {
      center: centerCoords,
      zoom: 12,
    });

    try {
      for (const obj of addresses) {
        const res = await ymaps.geocode(obj.address, { results: 1 });
        const firstGeoObject: any = res.geoObjects.get(0);

        if (firstGeoObject) {
          const coords = firstGeoObject.geometry.getCoordinates();
          const placemark = new ymaps.Placemark(
            coords,
            {
              balloonContent: obj.html,
            },
            {
              ...iconSettings,
            }
          );

          map.geoObjects.add(placemark);
        } else {
          console.error(
            `Геокодирование не дало результатов для адреса: ${obj.address}`
          );
        }
      }
    } catch (error) {
      console.error("Ошибка при геокодировании:", error);
    }
  };

  return (
    <div ref={mapRef} style={style} className="YandexMap">
      {children}
    </div>
  );
};

export default YandexMap;
