import Dropdown from "../../../components/Dropdowns/Dropdown";
import { CheckList } from "../../../components/Forms/CheckList";
import { FlexWrapper } from "../../../components/Galleries/FlexWrapper/FlexWrapper";
import { useWinW } from "../../../components/Hooks/useWinSize";
import { NamedObj } from "../../../redux/interfaces";
import "./JKFilters.scss";

export type FiltersType = {
  subwaysId: string[] | number[];
  apartmentTypesId: string[];
  search: string;
  deadline: string;
  priceFrom?: number;
  priceTo?: number;
};
export type JKFiltersProps = {
  setFilters: (filters: FiltersType) => void;
  filters: FiltersType;
  subways: NamedObj[];
  apartmentTypes: NamedObj[];
  onFind: () => void;
};

export const JKFilters: React.FC<JKFiltersProps> = ({
  setFilters,
  filters,
  subways,
  apartmentTypes,
  onFind,
}) => {
  const deadlineOptions = [
    { label: "Сдан", value: "passed" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
  ];
  const customCheckBox = <div className="checkBox"></div>;
  const renderFilters = [
    <input
      type="text"
      value={filters.search}
      onChange={(e) =>
        setFilters({
          ...filters,
          search: e.target.value,
        })
      }
      placeholder="ЖК / Застройщик"
    />,
    <Dropdown trigger={<p>Метро</p>} arrow>
      <CheckList
        multiple
        customCheckBox={customCheckBox}
        onChange={(subwaysId: string[] | number[]) =>
          setFilters({
            ...filters,
            subwaysId: subwaysId,
          })
        }
        values={filters.subwaysId}
        options={subways.map((sub) => ({
          label: sub.name,
          value: sub.id,
        }))}
      />
    </Dropdown>,
    <Dropdown trigger={<p>Срок сдачи</p>} arrow>
      <CheckList
        customCheckBox={customCheckBox}
        onChange={(deadline: string[]) =>
          setFilters({
            ...filters,
            deadline: deadline.length > 0 ? deadline[0] : "",
          })
        }
        values={[filters.deadline]}
        options={deadlineOptions}
      />
    </Dropdown>,
    <Dropdown trigger={<p>Типы квартир</p>} arrow>
      <CheckList
        multiple
        customCheckBox={customCheckBox}
        onChange={(apartmentTypesId: string[]) =>
          setFilters({
            ...filters,
            apartmentTypesId: apartmentTypesId,
          })
        }
        values={filters.apartmentTypesId}
        options={apartmentTypes.map((apartmentType) => ({
          label: apartmentType.name,
          value: apartmentType.id,
        }))}
      />
    </Dropdown>,
    <input
      type="number"
      value={filters.priceFrom}
      onChange={(e) =>
        setFilters({
          ...filters,
          priceFrom: Number(e.target.value),
        })
      }
      placeholder="Цена от"
    />,
    <input
      value={filters.priceTo}
      type="number"
      onChange={(e) =>
        setFilters({
          ...filters,
          priceTo: Number(e.target.value),
        })
      }
      placeholder="Цена до"
    />,
    <button onClick={onFind}>Найти</button>,
  ];
  const winW = useWinW();
  const rebuildFilters = winW <= 1200;
  return (
    <div className="jk-filters-parent">
      <div className="jk-filters">
        {renderFilters.slice(0, rebuildFilters ? 6 : 7).map((filter) => (
          <div className="filter">{filter}</div>
        ))}
      </div>
      <div className="find-btn-box filter">
        <button onClick={onFind}>Найти</button>
      </div>
    </div>
  );
};
