import React, { ReactNode } from "react";
import {CheckInput} from "./CheckInput";
import "./CheckList.scss";

type CheckListOption = {
  label: string | number;
  value: string | number;
};

type CheckListProps = {
  className?: string;
  options: CheckListOption[];
  values: any[];
  onChange: (values: any[]) => void;
  multiple?: boolean;
  customCheckBox?: ReactNode;
};

export const CheckList: React.FC<CheckListProps> = ({
  className = "",
  options,
  values,
  onChange,
  multiple = false,
  customCheckBox,
}) => {
  const handleChange = (optionValue: string | number) => {
    if (multiple) {
      if (values.includes(optionValue)) {
        onChange(values.filter((value) => value !== optionValue));
      } else {
        onChange([...values, optionValue]);
      }
    } else {
      onChange(optionValue === values[0] ? [] : [optionValue]);
    }
  };

  return (
    <div className={`${className} check-list`}>
      {options.map((option) => (
        <CheckInput
          key={option.value}
          checked={values.includes(option.value)}
          onChange={() => handleChange(option.value)}
          customCheckBox={customCheckBox}
        >
          {option.label}
        </CheckInput>
      ))}
    </div>
  );
};

