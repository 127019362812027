import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./BasePage.scss";
import { ThemePage } from "../Page/ThemePage/ThemePage";

export type BasePageProps = {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
};
export const BasePage: FC<BasePageProps> = ({
  children,
  header,
  footer,
  className = "",
}) => {
  return (
    <ThemePage>
      <div className={`${className} base-page `}>
        {header}
        {children}
        {footer}
      </div>
    </ThemePage>
  );
};
