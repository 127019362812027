import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./Header.scss";
import { BaseHeader } from "../../../components/Page/Header/BaseHeader";
import { useSelector } from "react-redux";
import { InitialState } from "../../../redux/interfaces";

export type HeaderProps = {

};

export const Header: FC<HeaderProps> = () => {

  const company = useSelector((state: InitialState) => state.company);
  

  return (
    <BaseHeader>
      <div className="primary">
        <div className="introduce">
          <div className="logo">
            <a href="/">
              <svg
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3333 29.9699H26.6667M2 25.1518V20.1936C2 17.8876 2 16.7345 2.30433 15.6726C2.57391 14.732 3.01695 13.8474 3.61166 13.0621C4.28304 12.1755 5.21497 11.4677 7.07883 10.0519L12.4233 5.99221C15.3119 3.79794 16.7562 2.70083 18.3511 2.27909C19.7584 1.90697 21.2416 1.90697 22.6489 2.27909C24.2438 2.70083 25.688 3.79796 28.5767 5.99221L33.9211 10.0519C35.7851 11.4677 36.7169 12.1755 37.3882 13.0621C37.9831 13.8474 38.4261 14.732 38.6956 15.6726C39 16.7345 39 17.8876 39 20.1936V25.1518C39 29.6491 39 31.8979 38.1038 33.6156C37.3155 35.1264 36.0577 36.355 34.5107 37.1249C32.7517 38 30.4493 38 25.8444 38H15.1556C10.5507 38 8.24825 38 6.48942 37.1249C4.9423 36.355 3.68447 35.1264 2.89616 33.6156C2 31.8979 2 29.6491 2 25.1518Z"
                  stroke="white"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div className="site-name">
            <a href="/">
              <h1>{company?.name}</h1>
            </a>
          </div>
        </div>
        <div className="site-map">
          <a href="/about/">О компании</a>
          <a href="/contacts/">Контакты</a>,
        </div>
      </div>
      <div className="secondary">
        <div className="item">
          <a href={`tel:${company?.phone}`}>{company?.phone}</a>
        </div>
      </div>
    </BaseHeader>
  );
};
