import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./City.scss";
import { JkCard } from "../components/JKCard/JKCard";

import { formatAddressHtml } from "../../utils";
import { SERVER_API } from "../../App";
import axios from "axios";
import YandexMap, { Address } from "../../components/Maps/YandexMap";
import { InitialState, JK, NamedObj } from "../../redux/interfaces";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FiltersType, JKFilters } from "../components/Filters/JKFilters";
import { ThemePage } from "../../components/Page/ThemePage/ThemePage";
import { Page } from "../components/Page";
import { useWinW } from "../../components/Hooks/useWinSize";
import { BTNMap, cityCenters } from "../components/Map/Map";
import { getCSSVariable } from "../../components/Utils/StylesHelper";
import { ScrollPagination } from "../../components/Pagination/ScrollPagination";

type CityProps = {
  cityName: string;
  showMapPage?: boolean;
};

export const City: FC<CityProps> = ({ cityName, showMapPage }) => {
  const { blockId = "" } = useParams();
  const [subways, setSubways] = useState<NamedObj[]>([]);
  const [blocks, setBlocks] = useState<JK[]>([]);
  const apartmentTypes = useSelector(
    (state: InitialState) => state.apartmentTypes
  );
  const [page, setPage] = useState(1);
  const [paginatorLoading, setPaginatorLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<FiltersType>({
    subwaysId: [],
    apartmentTypesId: [],
    search: "",
    deadline: "",
  });
  const [addresses, setAddresses] = useState<Address[]>([]);

  const fetchBlocks = async (page: number, attrFilters?: FiltersType) => {
    if (page === 1) setAddresses([]);

    let filt = attrFilters || filters;
    const subwayQuery = (filt.subwaysId || []).join(",");
    const apartmentTypeQuery = (filt.apartmentTypesId || []).join(",");
    const search = filt.search;
    const deadline = filt.deadline;
    const priceFrom = filt.priceFrom;
    const priceTo = filt.priceTo;

    if (subways.length === 0) {
      axios.get(`${SERVER_API}subways/?city=${cityName}`).then((resp) => {
        setSubways(resp.data.subways);
      });
    }
    try {
      const response = await fetch(
        `${SERVER_API}blocks/?city=${cityName}&page=${page}&search=${search}&subway=${subwayQuery}&deadline=${deadline}&apartmentType=${apartmentTypeQuery}&priceFrom=${
          priceFrom || ""
        }&priceTo=${priceTo || ""}&id=${blockId}`,
        {
          method: "GET",
        }
      );
      const reader = response!.body!.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        let boundary = buffer.indexOf("}{");
        while (boundary !== -1) {
          const completeJSON = buffer.slice(0, boundary + 1);
          buffer = buffer.slice(boundary + 1);

          try {
            const line_json = JSON.parse(completeJSON);
            setAddresses((prev) => [
              ...prev,
              {
                html: formatAddressHtml(line_json),
                address: line_json.address,
              },
            ]);
            setBlocks((prev) => [...prev, line_json]);
          } catch (error) {}

          boundary = buffer.indexOf("}{");
        }
      }
      if (buffer.trim()) {
        try {
          const line_json = JSON.parse(buffer);
          setBlocks((prev) => [...prev, line_json]);
          setAddresses((prev) => [
            ...prev,
            {
              html: formatAddressHtml(line_json),
              address: line_json.address,
            },
          ]);
          setPaginatorLoading(false);
        } catch (error) {}
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (blocks.length % 15 === 0 && blocks.length !== 0) {
      setPaginatorLoading(false);
    }
  }, [blocks]);

  const filterAttributes: (keyof FiltersType)[] = [
    "subwaysId",
    "apartmentTypesId",
    "search",
    "deadline",
    "priceFrom",
    "priceTo",
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const newFilters = filterAttributes.reduce((acc, attr) => {
      const paramValue = searchParams.get(attr);

      if (
        (attr === "apartmentTypesId" || attr === "subwaysId") &&
        paramValue !== null
      ) {
        (acc as any)[attr] = paramValue.split(",");
      } else if (paramValue !== null) {
        (acc as any)[attr] = paramValue;
      }
      return acc;
    }, {} as Partial<FiltersType>);
    setFilters({ ...filters, ...newFilters });
    onFind({ ...filters, ...newFilters });
    setLoading(false);
  }, [window.location.search]);

  const onFind = (attrFilters?: FiltersType) => {
    setBlocks([]);
    setPage(1);
    fetchBlocks(1, attrFilters);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onFind(filters);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filters]);

  useEffect(() => {
    if (!loading) fetchBlocks(page);
  }, [page]);
  const winW = useWinW();
  const renderBlocks =
    addresses.length > 0
      ? [
          BTNMap(addresses, cityName, { width: "50%" }),
          ...blocks.map((block, index) => <JkCard jk={block} />),
        ]
      : blocks.map((block, index) => <JkCard jk={block} />);
  const renderBlocksSlice = winW < 600 ? 1 : winW < 1200 ? 2 : 3;
  const jkFilters = (
    <JKFilters
      onFind={() => onFind()}
      setFilters={setFilters}
      filters={filters}
      subways={subways}
      apartmentTypes={apartmentTypes}
    />
  );

  return (
    <Page>
      <ScrollPagination
        setLoading={setPaginatorLoading}
        loading={paginatorLoading}
        page={page}
        setPage={setPage}
        hasMore={hasMore}
      >
        <div className="city page">
          {jkFilters}
          {showMapPage ? (
            <div className="YandexMap-parent">
              <YandexMap
                iconSettings={{ iconColor: getCSSVariable("--bg-1") }}
                centerCoords={(cityCenters as any)[cityName] as any}
                addresses={
                  blockId
                    ? addresses.filter(
                        (address) =>
                          blocks.find((b) => b.id === blockId)?.address ===
                          address.address
                      )
                    : addresses
                }
                style={{
                  width: "100%",
                  minHeight: "128px",
                  borderRadius: "8px",
                  overflow: "hidden",
                  position: "relative",
                }}
              />
            </div>
          ) : (
            <>
              <div className="jk-grid map">
                {renderBlocks.slice(0, renderBlocksSlice).map((el) => el)}
              </div>
              <div className="jk-grid">
                {renderBlocks.slice(renderBlocksSlice).map((el) => el)}
              </div>
            </>
          )}
        </div>
      </ScrollPagination>
    </Page>
  );
};
