import React, { FC, ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { InitialState } from "../../../redux/interfaces";
import { themeClasses } from "../../../redux/reducers";

export interface ThemePage {
  children?: ReactNode;
}

export const ThemePage: FC<ThemePage> = ({ children }) => {
  const selectedThemeClass = useSelector(
    (state: InitialState) => state.selectedThemeClass
  );
  useEffect(() => {
    const body = document.body;
    themeClasses.forEach((cls) => {
      body.classList.remove(cls);
    });
    body.classList.add(selectedThemeClass);
  }, [selectedThemeClass, themeClasses]);

  return <>{children}</>;
};
