import { useEffect, useState } from "react";
import "./JKModal.scss";

import axios from "axios";

import { useSelector } from "react-redux";
import { ApartmentType, InitialState, JK } from "../../redux/interfaces";
import { formatPriceSpaces } from "../../utils";
import { SERVER_API } from "../../App";
import { getCookie } from "../../components/Utils/Fetching";
import { CheckInput } from "../../components/Forms/CheckInput";
import Modal from "../../components/Modal/Modal";

export type JKModalProps = {
  jk: JK;
  apartment?: ApartmentType;
  title:
    | "Забронировать квартиру"
    | "Рассчитать ипотеку"
    | "Получить консультацию"
    | "Заказать звонок";
  theme:
    | "Хочу получить консультацию по квартире в ЖК"
    | "Хочу получить расчет ипотеки для квартиры в ЖК"
    | "Хочу забронировать квартиру в ЖК"
    | "Хочу заказать звонок по поводу ЖК";
};

const JKModal: React.FC<JKModalProps & { onClose?: () => void }> = ({
  title,
  theme,
  jk,
  apartment,
  onClose,
}) => {
  const company = useSelector((state: InitialState) => state.company);
  const [formData, setFormData] = useState({
    message: apartment
      ? `Здравствуйте! ${theme} ${jk.name}: ${apartment.room_size.name} ${
          apartment.area_rooms_total
        }м2, этаж ${apartment.floor} ${
          apartment.floors
        }, цена ${formatPriceSpaces(apartment.price)} руб`
      : `Здравствуйте! ${theme} ${jk.name}`,
    phone: "",
    name: "",
    accepted: false,
  });
  const [showErrors, setShowErrors] = useState(false);
  const onSend = () => {
    if (
      !formData.message ||
      !formData.phone ||
      !formData.name ||
      !formData.accepted
    ) {
      setShowErrors(true);
    } else {
      axios.post(
        `${SERVER_API}users/order/`,
        {
          company_id: company?.id,
          theme: title,
          message: formData.message,
          phone: formData.phone,
          name: formData.name,
        },
        {
          headers: {
            "X-CSRFToken": getCookie("csrftoken"),
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      onClose?.();
    }
  };
  useEffect(() => {
    setShowErrors(false);
  }, [formData]);

  return (
    <Modal className="JKModal" onClose={onClose}>
      <div className="title">{title}</div>
      <div className="fields-parent">
        <div className="fields">
          <div className="user-data-fields">
            <label className={`${showErrors && !formData.name ? "error" : ""}`}>
              <p>Как к Вам обращаться?</p>
              <input
                type="text"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  })
                }
              />
            </label>
            <label
              className={`${showErrors && !formData.phone ? "error" : ""}`}
            >
              <p>Ваш номер телефона</p>
              <input
                type="tel"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setFormData({
                    ...formData,
                    phone: value,
                  });
                }}
                value={formData.phone}
              />
            </label>
          </div>
          <label>
            <p>Сообщение</p>
            <div className="textarea-parent">
              <textarea
                className={`${showErrors && !formData.message ? "error" : ""}`}
                value={formData.message}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    message: e.target.value,
                  })
                }
                rows={5}
              />
            </div>
          </label>
        </div>
        <div
          className={`controls ${
            showErrors && !formData.accepted ? "error" : ""
          }`}
        >
          <CheckInput
            customCheckBox={<div className="checkBox"></div>}
            onChange={(e) =>
              setFormData((prev) => {
                return {
                  ...prev,
                  accepted: e.target.checked,
                };
              })
            }
            checked={formData.accepted}
          >
            Я согласен на обработку моих персональных данных
          </CheckInput>
          <button onClick={onSend}>{title.split(" ")[0]}</button>
        </div>
      </div>
    </Modal>
  );
};

export default JKModal;
