import { ReactNode } from "react";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { BasePage } from "../../components/Page/BasePage";

type PageProps = {
  children: ReactNode;
};

export const Page: React.FC<PageProps> = ({ children }) => (
  <BasePage header={<Header />} footer={<Footer />}>
    {children}
  </BasePage>
);
