import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./CityCard.scss";
import axios from "axios";
import { SERVER_API } from "../../../App";
import { cityNormalizer, formatPrice, getCountedWord } from "../../../utils";
import { useWinW } from "../../../components/Hooks/useWinSize";

export type CityCardProps = {
  img: string;
  className?: string;
  cityName: string;
};

export type CityData = {
  blocks_count: number;
  apartments_count: number;
  min_price: number;
};
export const CityCard: FC<CityCardProps> = ({
  img,
  className = "",
  cityName,
}) => {
  const [data, setData] = useState<CityData | null>(null);
  useEffect(() => {
    axios.get(`${SERVER_API}city_data/${cityName}`).then((resp) => {
      setData(resp.data);
    });
  }, []);
  const shorts = ["spb", "msk", "nsk"];
  const winW = useWinW();

  const applyShort = shorts.includes(cityName) && winW > 800;

  return (
    <div className={`${className} city-card`}>
      <img src={img} alt="" />
      <a
        href={`/${cityName}/`}
        className={`btn ${applyShort ? "short" : "full"}`}
      >
        {applyShort ? "Выбрать" : "Выбрать новостройку"}
      </a>
      <div className="shadow-box">
        <h1>
          <span></span>
          {cityNormalizer(cityName)}
        </h1>
        <p>
          {data?.apartments_count}{" "}
          {getCountedWord(data?.apartments_count || 0, "квартир")} в{" "}
          {data?.blocks_count} ЖК
        </p>
        <p>от {data && formatPrice(data?.min_price)} ₽</p>
      </div>
    </div>
  );
};
