// Scrollbar.tsx
import React, { useRef, useState, useEffect, CSSProperties } from "react";
import "./Scrollbar.scss";

type ScrollbarProps = {
  children: React.ReactNode;
  style?:CSSProperties;
};

const Scrollbar: React.FC<ScrollbarProps> = ({ children, style }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const updateThumbPosition = () => {
    const content = contentRef.current;
    const thumb = thumbRef.current;
    const track = trackRef.current;
    if (content && thumb && track) {
      const contentHeight = content.scrollHeight;
      const containerHeight = content.clientHeight;
      const scrollRatio = containerHeight / contentHeight;
      if (containerHeight >= contentHeight) {
        thumb.style.display = "none";
        track.style.display = "none";
      } else {
        thumb.style.display = "block";
        track.style.display = "block";
        thumb.style.height = `${scrollRatio * containerHeight}px`;
        thumb.style.top = `${
          (content.scrollTop / contentHeight) * containerHeight
        }px`;
      }
    }
  };

  updateThumbPosition();

  useEffect(() => {
    document.addEventListener("mousemove", handleThumbMouseMove);
    document.addEventListener("mouseup", handleThumbMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleThumbMouseMove);
      document.removeEventListener("mouseup", handleThumbMouseUp);
    };
  }, [dragging]);

  const handleScroll = () => {
    updateThumbPosition();
  };

  const handleThumbMouseDown = (e: React.MouseEvent) => {
    setDragging(true);
    setStartY(e.clientY);
    const content = contentRef.current;
    if (content) {
      console.log(content.scrollTop);
      setScrollTop(content.scrollTop);
    }
  };

  const handleThumbMouseMove = (e: MouseEvent) => {
    if (!dragging) return;
    const content = contentRef.current;
    if (content) {
      const deltaY = e.clientY - startY;
      const scrollHeight = content.scrollHeight - content.clientHeight;
      content.scrollTop =
        scrollTop + (deltaY / content.clientHeight) * scrollHeight;
      console.log(scrollTop + (deltaY / content.clientHeight) * scrollHeight);
    }
  };

  const handleThumbMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    updateThumbPosition();
  }, []);

  return (
    <div className="scrollbar-container">
      <div
        style={style}
        ref={contentRef}
        className="scroll-content"
        onScroll={handleScroll}
      >
        {children}
      </div>
      <div ref={trackRef} className="track">
        <div
          className="thumb"
          ref={thumbRef}
          onMouseDown={handleThumbMouseDown}
        />
      </div>
    </div>
  );
};

export default Scrollbar;
