import { ActionType } from "./actions";
import { InitialState } from "./interfaces";

export const themeClasses = ["light", "dark"];

const initialState: InitialState = {
  company: null,
  apartmentTypes: [],
  winSize: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  selectedThemeClass: "dark",
};

const rootReducer = (
  state: InitialState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case "setWinSize":
       return {
         ...state,
         winSize: action.data.winSize,
       };
    case "setCompany":
      return {
        ...state,
        company: action.data.company,
      };
    case "setApartmentTypes":
      return {
        ...state,
        apartmentTypes: action.data.apartmentTypes,
      };
    default:
      return state;
  }
};

export default rootReducer;
