import React, { FC, ReactNode } from "react";
import "./FlexWrapper.scss";

export interface FlexWrapperProps {
  items: ReactNode[];
  className?: string;
  direction?: "horizontal" | "vertical";
}

export const FlexWrapper: FC<FlexWrapperProps> = ({
  items,
  className,
  direction = "horizontal",
}) => {
  return (
    <div className={`flex-wrapper ${direction} ${className || ""}`}>
      {items.map((item) => (
        <div className="item">{item}</div>
      ))}
    </div>
  );
};
