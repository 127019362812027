import React, { useState, useRef, useEffect } from "react";
import "./Dropdown.scss";
import Scrollbar from "../Scrollbar/Scrollbar";
import { getComputed } from "../Utils/StylesHelper";
import { useWinSize } from "../Hooks/useWinSize";

type DropdownProps = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  arrow?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({ trigger, children, arrow }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const [triggerHeight, setTriggerHeight] = useState(0);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [dropdownTransition, setDropdownTransition] = useState("");

  const dropdownRef = useRef(null);
  const contentRef = useRef(null);
  const triggerRef = useRef(null);
  const winSize = useWinSize();
  useEffect(() => {
    if (dropdownRef.current) {
      setDropdownTransition(
        (dropdownRef.current as HTMLElement).style.transition
      );
    }
  }, [dropdownRef, winSize]);

  useEffect(() => {
    const contentHTML = contentRef.current;
    if (contentHTML) {
      const height = getComputed(contentHTML, "maxHeight");
      setContentHeight(height);
      setIsOpen(false);
    }
  }, [contentRef, winSize]);

  useEffect(() => {
    const triggerHTML = triggerRef.current;
    if (triggerHTML) {
      const height = getComputed(triggerHTML, "height");
      setTriggerHeight(height);
    }
  }, [triggerRef, winSize]);

  useEffect(() => {
    let contHeight;

    const contentHTML = contentRef.current;
    if (contentHTML && isOpen) {
      contHeight = getComputed(contentHTML, "height");
      if (contHeight) setContentHeight(contHeight);
    }
    if (triggerHeight) {
      if (isOpen) {
        const marginTop = getComputed(contentRef!.current!, "marginTop");
        const marginBottom = getComputed(triggerRef!.current!, "marginBottom");
        setDropdownHeight(
          triggerHeight + contentHeight + marginTop + marginBottom
        );
        (contentRef!.current! as HTMLElement).style.top = triggerHeight + "px";
        (dropdownRef!.current! as HTMLElement).style.zIndex = "2000";
      } else {
        setDropdownHeight(triggerHeight);
        (contentRef!.current! as HTMLElement).style.top = triggerHeight + "px";
        setTimeout(() => {
          (dropdownRef!.current! as HTMLElement).style.zIndex = "0";
        }, parseFloat(dropdownTransition));
      }
    }
    if (dropdownHeight === 0) {
      (dropdownRef!.current! as HTMLElement).style.transition = "unset";
    } else {
      (dropdownRef!.current! as HTMLElement).style.transition =
        dropdownTransition;
    }
  }, [triggerHeight, isOpen, contentHeight, winSize]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        height: triggerHeight + "px",
      }}
      className="dropdown-parent"
    >
      <div
        ref={dropdownRef}
        style={{
          height: dropdownHeight + "px",
        }}
        className={`dropdown ${isOpen ? "open" : "closed"}`}
      >
        <div
          ref={triggerRef}
          onClick={toggleDropdown}
          className="dropdown-trigger"
        >
          {trigger}
          {arrow && (
            <div className="arrow">
              <svg
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 7L7 0.999999L1 7"
                  stroke="black"
                  stroke-opacity="0.7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
        <div ref={contentRef} className={`dropdown-content`}>
          <Scrollbar style={{ maxHeight: contentHeight }}>{children}</Scrollbar>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
