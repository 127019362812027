import { CSSProperties } from "react";
import YandexMap, { Address } from "../../../components/Maps/YandexMap";
import "./Map.scss";
import { getCSSVariable } from "../../../components/Utils/StylesHelper";

export const cityCenters = {
  spb: [59.9342802, 30.3350986], // Санкт-Петербург
  msk: [55.755826, 37.6172999], // Москва
  nsk: [55.0083526, 82.9357327], // Новосибирск
  ekb: [56.8389261, 60.6057025], // Екатеринбург
  kzn: [55.8304307, 49.0660806], // Казань
  rostov: [47.2357137, 39.701505], // Ростов-на-Дону
  krasnodar: [45.03547, 38.975313], // Краснодар
};



export const BTNMap = (
  addresses: Address[],
  cityName: string,
  style?: CSSProperties,
  blockId?: string
) => (
  <YandexMap
    iconSettings={{ iconColor: getCSSVariable("--bg-1") }}
    centerCoords={(cityCenters as any)[cityName] as any}
    addresses={addresses}
    style={{
      width: "100%",
      height: "100%",
      minHeight: "128px",
      borderRadius: "8px",
      overflow: "hidden",
      position: "relative",
      ...style,
    }}
  >
    <div className="map-btn-parent">
      <a
        href={`/${cityName}/map/${blockId ? blockId : ""}`}
        className="map-btn"
      >
        <p>Новостройк{blockId ? "а" : "и"} на карте</p>
      </a>
    </div>
  </YandexMap>
);
