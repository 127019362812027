import { useState, useEffect } from "react";
import { setWinSize } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { InitialState } from "../../redux/interfaces";

export const useWinSizeSetter = () => {
  const dispatch = useDispatch();
  const winSize = useSelector((state: InitialState) => state.winSize)
  useEffect(() => {
    const handleResize = () => {
      dispatch(
        setWinSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [winSize]);
};

// Хук для получения текущих размеров окна
export const useWinSize = () => {
  const winSize = useSelector((state: InitialState) => state.winSize);
  return winSize;
};

// Хук для получения текущей ширины окна
export const useWinW = () => {
  const winSize = useWinSize();
  return winSize.width;
};

// Хук для получения текущей высоты окна
export const useWinH = () => {
  const winSize = useWinSize();
  return winSize.height;
};
