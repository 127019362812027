import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { useDispatch } from "react-redux";
import { setApartmentTypes, setCompany } from "./redux/actions";
import axios from "axios";
import { useWinSizeSetter } from "./components/Hooks/useWinSize";
import { Contact } from "./pages/Contact/Contact";
import { About } from "./pages/About/About";
import { City } from "./pages/City/City";
import { Jk } from "./pages/Jk/Jk";

// export const HOST = "127.0.0.1"
// export const REACT_DOMAIN = `http://${HOST}:3000`;
// export const SERVER_DOMAIN = `http://${HOST}:8080`;
// export const SERVER_API = SERVER_DOMAIN + "/api/";

export const HOST = "novostroyka.center";
export const REACT_DOMAIN = `https://${HOST}`;
export const SERVER_DOMAIN = `https://${HOST}`;
export const SERVER_API = SERVER_DOMAIN + "/api/";


function App() {
  const [cityNames, setCityNames] = useState<string[]>([]);
  const dispatch = useDispatch();

  useWinSizeSetter();

  useEffect(() => {
    axios.get(SERVER_API + "apartment_types/").then((resp) => {
      dispatch(setApartmentTypes(resp.data.apartment_types));
    });
  }, []);

  useEffect(() => {
    axios
      .get(SERVER_API + "users/company/?domain=" + window.location.hostname)
      .then((resp) => {
        setCityNames(resp.data.company.city_names);
        dispatch(setCompany(resp.data.company));
      });
  }, []);

  return (
    <Router>
      <Routes>
        {cityNames.length === 1 ? (
          <>
            <Route path="/" element={<City cityName={cityNames[0]} />} />
            <Route
              path={`${cityNames[0]}/map/`}
              element={<City showMapPage cityName={cityNames[0]} />}
            />
            <Route
              path={`${cityNames[0]}/map/:blockId/`}
              element={<City showMapPage cityName={cityNames[0]} />}
            />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            {cityNames.map((name) => (
              <>
                <Route
                  path={`/${name}/`}
                  element={<City cityName={name} />}
                />
                <Route
                  path={`/${name}/map/`}
                  element={<City showMapPage cityName={name} />}
                />
                <Route
                  path={`/${name}/map/:blockId/`}
                  element={<City showMapPage cityName={name} />}
                />
              </>
            ))}
          </>
        )}
        <Route path="/about/" element={<About />} />
        <Route path="/contacts/" element={<Contact />} />
        <Route path="/blocks/:blockId/" element={<Jk />} />
        <Route
          path="/blocks/:blockId/apartment/:apartmentId/"
          element={<Jk />}
        />
      </Routes>
    </Router>
  );
}

export default App;
