import React, { ChangeEvent, ReactNode } from "react";
import "./CheckInput.scss";

type CheckInputProps = {
  children: ReactNode;
  className?: string;
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  customCheckBox?: ReactNode;
};

export const CheckInput: React.FC<CheckInputProps> = ({
  children,
  className = "",
  checked = false,
  onChange,
  customCheckBox,
}) => {
  return (
    <label className={`check-input ${className} ${checked ? "checked" : ""}`}>
      {customCheckBox}
      <input
        type="checkbox"
        hidden={Boolean(customCheckBox)}
        checked={checked}
        onChange={onChange}
      />
      {children}
    </label>
  );
};
