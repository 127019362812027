import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./Contact.scss";
import { Page } from "../components/Page";
import YandexMap from "../../components/Maps/YandexMap";
import { InitialState } from "../../redux/interfaces";
import { useSelector } from "react-redux";
import { cityCenters } from "../components/Map/Map";
import { getCSSVariable } from "../../components/Utils/StylesHelper";

export type ContactProps = {};

export const Contact: FC<ContactProps> = () => {
  const company = useSelector((state: InitialState) => state.company);

  return (
    <Page>
      <div className="contact page">
        <div className="titled">
          <h1>Контакты</h1>
          <div className="contacts-parent">
            <div className="contacts">
              <div className="address">
                <p className="mark">Юридический адрес:</p>
                <p>{company?.name}</p>
                <p>{company?.address}</p>
              </div>

              <div className="email">
                <p className="mark">Email:</p>
                <p>{company?.email}</p>
              </div>
              <div className="phone">
                <p className="mark">Телефон:</p>
                <p>{company?.phone}</p>
              </div>
            </div>
            <YandexMap
              centerCoords={(cityCenters as any)[company?.city || ""] as any}
              addresses={[{ address: company?.address || "", html: `` }]}
              iconSettings={{ iconColor: getCSSVariable("--bg-1") }}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};
