import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./About.scss";
import { Page } from "../components/Page";
import { InitialState } from "../../redux/interfaces";
import { useSelector } from "react-redux";
import "./About.scss";
export type AboutProps = {};

export const About: FC<AboutProps> = () => {
  const company = useSelector((state: InitialState) => state.company);

  return (
    <Page>
      <div className="about page">
        <div className="titled">
          <h1>О компании</h1>
          <div className="description">{company?.description}</div>
        </div>
        {(company?.certificates?.length || 0) > 0 && (
          <div className="titled">
            <h1>Награды и сертификаты</h1>
            <div className="certificates">
              {company?.certificates.map((cert) => (
                <div className="cert">
                  <img src={cert.photo} alt="" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};
