import React, { FC, useEffect, useState } from "react";
import "./Home.scss";
import ekb from "./img/ekb.png";
import kzn from "./img/kzn.png";
import spb from "./img/spb.png";
import rostov from "./img/rostov.png";
import msk from "./img/msk.png";
import nsk from "./img/nsk.png";
import krasnodar from "./img/krasnodar.png";
import block from "./img/block.png";
import { JK } from "../../redux/interfaces";
import { SERVER_API } from "../../App";
import { cityNormalizer, formatPrice } from "../../utils";
import { JkCard } from "../components/JKCard/JKCard";

import { ThemePage } from "../../components/Page/ThemePage/ThemePage";
import { useWinW } from "../../components/Hooks/useWinSize";

import { HorizontalScroll } from "../../components/Galleries/HorizontalScroll/HorizontalScroll";
import { FlexWrapper } from "../../components/Galleries/FlexWrapper/FlexWrapper";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { CityCard } from "../components/CityCard/CityCard";
import { Page } from "../components/Page";
export interface HomeProps {}

export const Home: FC<HomeProps> = () => {
  const cities = [
    {
      img: ekb,
      name: "ekb",
    },
    {
      img: kzn,
      name: "kzn",
    },
    {
      img: spb,
      name: "spb",
    },
    {
      img: msk,
      name: "msk",
    },
    {
      img: nsk,
      name: "nsk",
    },
    {
      img: krasnodar,
      name: "krasnodar",
    },
    {
      img: rostov,
      name: "rostov",
    },
  ];

  const [recommendations, setRenderRecommendations] = useState<JK[]>([]);
  const winW = useWinW();

  const fetchBlocks = async () => {
    try {
      const response = await fetch(`${SERVER_API}blocks/?random=1`, {
        method: "GET",
      });
      const reader = response!.body!.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        let boundary = buffer.indexOf("}{");
        while (boundary !== -1) {
          const completeJSON = buffer.slice(0, boundary + 1);
          buffer = buffer.slice(boundary + 1);

          try {
            const line_json = JSON.parse(completeJSON);

            setRenderRecommendations((prev) => [...prev, line_json]);
          } catch (error) {}

          boundary = buffer.indexOf("}{");
        }
      }
      if (buffer.trim()) {
        try {
          const line_json = JSON.parse(buffer);
          setRenderRecommendations((prev) => [...prev, line_json]);
        } catch (error) {}
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchBlocks();
  }, []);

  const comments = [
    <div className="comment">
      <div className="avatar">
        <img
          src="https://gagaru.club/uploads/posts/2023-02/1676219027_gagaru-club-p-krasivoe-muzhskoe-litso-krasivo-12.jpg"
          alt=""
        />
      </div>
      <div>
        <h6>Александр</h6>
        <p>
          «Очень доволен работой вашей компании. Менеджер помог мне разобраться
          во всех нюансах и подобрать идеальный вариант для покупки. Большое вам
          спасибо!»
        </p>
      </div>
    </div>,
    <div className="comment">
      <div className="avatar">
        <img
          src="https://cdn1.flamp.ru/9dd52ae09c05bc7931f116f361433385.PNG"
          alt=""
        />
      </div>
      <div>
        <h6>Мария</h6>
        <p>
          «Великолепный сервис! Специалисты помогли мне найти квартиру мечты.
          Все прошло быстро и без проблем. Рекомендую всем!»
        </p>
      </div>
    </div>,
    <div className="comment">
      <div className="avatar">
        <img
          src="https://cdn1.flamp.ru/698eaa042532f23f71308ba1b3a496b9.jpg"
          alt=""
        />
      </div>
      <div>
        <h6>Сергей</h6>
        <p>
          «Профессиональная команда! Сотрудники очень внимательны и отзывчивы,
          помогли выбрать лучший вариант по моим запросам. Спасибо за отличную
          работу!»
        </p>
      </div>
    </div>,
    <div className="comment">
      <div className="avatar">
        <img
          src="https://avatars.mds.yandex.net/i?id=5c1e7234d276925596f7769703ab8c20_l-5334779-images-thumbs&n=13"
          alt=""
        />
      </div>
      <div>
        <h6>Ольга</h6>
        <p>
          «Прекрасная компания! Менеджеры помогли найти идеальную квартиру,
          учитывая все мои пожелания. Спасибо за вашу помощь!»
        </p>
      </div>
    </div>,
    <div className="comment">
      <div className="avatar">
        <img
          src="https://avatars.mds.yandex.net/i?id=9a4f6358dfba6d9d2afeca879c3b192c_l-10995124-images-thumbs&n=13"
          alt=""
        />
      </div>
      <div>
        <h6>Дмитрий</h6>
        <p>
          «Отличный опыт! Все было организовано на высшем уровне. Благодарю за
          качественную работу и индивидуальный подход.»
        </p>
      </div>
    </div>,
    <div className="comment">
      <div className="avatar">
        <img
          src="https://yobte.ru/uploads/posts/2019-11/goluboglazye-brjunetki-98-foto-12.jpg"
          alt=""
        />
      </div>
      <div>
        <h6>Екатерина</h6>
        <p>
          «Очень довольна работой компании! Менеджеры профессионально подошли к
          моим запросам и помогли быстро найти подходящий вариант. Спасибо!»
        </p>
      </div>
    </div>,
  ];

  return (
    <Page>
      <div className="home page">
        <FlexWrapper
          items={cities.map((city) => (
            <CityCard cityName={city.name} img={city.img} />
          ))}
        />
        <div className="titled">
          <h1 className="title">Наши рекомендации</h1>
          <div className="jk-grid">
            {recommendations.map((jk) => (
              <JkCard jk={jk} />
            ))}
          </div>
        </div>
        <div className="titled">
          <h1 className="title">Отзывы</h1>
          <HorizontalScroll
            showCount={winW <= 750 ? 1 : winW <= 1250 ? 2 : 3}
            dots
            infinite
            items={comments.map((comment, index) => comment)}
          />
        </div>
      </div>
    </Page>
  );
};
