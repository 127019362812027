import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./Footer.scss";
import { InitialState } from "../../../redux/interfaces";
import { useSelector } from "react-redux";
import { cityNormalizer } from "../../../utils";

export type FooterProps = {};
export const Footer: FC<FooterProps> = () => {
  const apartmentTypes = useSelector(
    (state: InitialState) => state.apartmentTypes
  );
  const company = useSelector((state: InitialState) => state.company);

  return (
    <footer>
      <div className="container">
        <div className="sitemap">
          <h3>Карта сайта</h3>
          <ul>
            <li>
              <a href="#">Главная</a>
            </li>
            {company?.city_names?.map((city) => (
              <li className="dot">
                <a href={`/${city}/`}>{cityNormalizer(city)}</a>
              </li>
            ))}
            <li>
              <a href="/about/">О компании</a>
            </li>
            <li>
              <a href="/contacts/">Контакты</a>
            </li>
          </ul>
        </div>
        <div className="types">
          <h3>Типы квартир</h3>
          <ul>
            {company?.city_names?.length === 1
              ? apartmentTypes.map((type) => (
                  <li>
                    <a href={`/?apartmentTypesId=${[type.id].join(",")}`}>
                      {type.name}
                    </a>
                  </li>
                ))
              : apartmentTypes.map((type) => <li>{type.name}</li>)}
          </ul>
        </div>
        <div></div>
        <div className="contact--social">
          <div className="contact">
            <h3>Связь с нами</h3>
            <p>Телефон</p>
            <a href={`tel:${company?.phone}`}>
              <p>{company?.phone}</p>
            </a>
            <p>E-mail</p>
            <a href={`mailto:${company?.email}`}>
              <p>{company?.email}</p>
            </a>
          </div>

          <div className="social">
            <h3>Социальные сети</h3>
            <ul>
              {company?.socials?.map((soc) => (
                <li>
                  <a
                    dangerouslySetInnerHTML={{
                      __html: soc.svg,
                    }}
                    href={soc.link}
                  />
                  <a href={soc.link}>{soc.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024</p>
          <a href="#">Политика конфиденциальности</a>
        </div>
      </div>
    </footer>
  );
};
