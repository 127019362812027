import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./HorizontalScroll.scss";
import { useWinW } from "../../Hooks/useWinSize";
import { getComputed } from "../../Utils/StylesHelper";

export const getPosition = (
  event: TouchEvent | MouseEvent,
  direction: "X" | "Y" = "X"
): number => {
  if ("touches" in event && event.touches.length === 1) {
    return direction === "Y"
      ? event.touches[0].clientY
      : event.touches[0].clientX;
  }
  return direction === "Y"
    ? (event as MouseEvent).clientY
    : (event as MouseEvent).clientX;
};

type Auto = {
  on: boolean;
  autoStopAfter: "any interaction" | "timer" | "never";
  stopTimer?: number;
  animTimeS: number;
  intervalMS: number;
};

type HorizontalScrollComponentProps = {
  className?: string;
  items: ReactNode[];
  itemClick?: (index: number) => void;
  showCount?: number;
  animTime?: number;
  currentIndex?: number;
  setCurrentIndex?: (index: number) => void;
  auto?: Auto;
  infinite?: boolean;
  dots?: boolean;
  LeftArrowSVG?: ReactNode;
  RightArrowSVG?: ReactNode;
  shadows?: boolean;
  arrows?: boolean;
  trashHold?: number;
  reference?: React.RefObject<HTMLDivElement>;
  scrollLockPoint?: number;
};

export const HorizontalScroll: React.FC<HorizontalScrollComponentProps> = (
  props
) => {
  const {
    scrollLockPoint = 1200,
    reference,
    className,
    items,
    itemClick,
    showCount = 1,
    animTime = 0.5,
    currentIndex,
    setCurrentIndex,
    auto,
    infinite,
    dots,
    LeftArrowSVG,
    RightArrowSVG,
    arrows,
    shadows,
    trashHold = 35,
  } = props;
  const [startX, setStartX] = useState(0);
  const itemsCount = items.length;
  const itemsRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [curMediaIndex, setCurMediaIndex] = useState<number>(0);
  const [lastActionDate, setLastActionDate] = useState(Date.now());
  const winW = useWinW();
  const [prevIndex, setPrevIndex] = useState<number | null>(null);
  const [stopAuto, setStopAuto] = useState(false);
  const [itemWidth, setItemWidth] = useState(0);
  const galleryRef = useRef(null);
  const ref = reference || galleryRef;
  const [galleryPrevWidth, setGalleryPrevWidth] = useState("");
  useEffect(() => {
    if (auto?.autoStopAfter === "timer" && auto.stopTimer) {
      const timeout = setTimeout(() => {
        setStopAuto(true);
      }, auto.stopTimer);
      return clearTimeout(timeout);
    }
  }, [auto]);

  useEffect(() => {
    if (currentIndex !== undefined && currentIndex !== curMediaIndex)
      setCurMediaIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (setCurrentIndex) {
      setCurrentIndex(curMediaIndex);
      handleDotClick(curMediaIndex);
    }
  }, [curMediaIndex]);

  useEffect(() => {
    const itemsHTML = itemsRef.current as HTMLElement;
    if (itemsHTML) {
      console.log(ref!.current!);
      if (itemWidth === 0) {
        setGalleryPrevWidth(ref!.current!.style.width);
      }

      const width = getComputed(ref!.current!, "width") / showCount;
      console.log(width);

      // const item = itemsHTML.querySelector(
      //   `${!infinite ? ".item" : ".item:nth-child(3)"}`
      // ) as HTMLElement;
      // setItemWidth(item?.offsetWidth || 0);
      setItemWidth(width || 0);

      if (infinite) {
        itemsHTML.style.transform = `translateX(-${
          width * (items.length + 1)
        }px)`;
      }

      // if (infinite) {
      //   itemsHTML.style.transform = `translateX(-${
      //     (item?.offsetWidth || 0) * (items.length + 1)
      //   }px)`;
      // }
    }
  }, [itemsRef.current, itemWidth]);

  useEffect(() => {
    if (ref.current && itemWidth !== 0) {
      setItemWidth(0);
      ref!.current!.style.width = galleryPrevWidth;
    }
  }, [winW]);

  useEffect(() => {
    const itemsHTML = itemsRef.current as HTMLElement;
    if (itemsHTML) {
      (
        Array.from(itemsHTML.querySelectorAll(".item")) as HTMLElement[]
      ).forEach((el) => {
        if (infinite) {
          el.style.width = window.getComputedStyle(el).width;
        }
        el.querySelectorAll("img").forEach((img) => {
          img.ondragstart = (e) => e.preventDefault();
        });
      });
    }
  }, []);

  useEffect(() => {
    if (auto?.on && !stopAuto) {
      const interval = setInterval(() => {
        const itemsHTML = itemsRef.current as HTMLElement;
        itemsHTML.style.transition = `transform ${auto.animTimeS}s ease-out`;
        rightClick();
      }, auto.intervalMS);
      return () => clearInterval(interval);
    }
  }, [auto, curMediaIndex]);

  const getItemsWidthSum = (index: number) => {
    return itemWidth * (index + (infinite ? 1 : 0));
  };

  const moveRight = () => {
    const itemsParent = itemsRef.current as HTMLElement;
    if (itemsParent) {
      const allItems = itemsParent.querySelectorAll(".item:not(.hide)");
      const mostRigthEl = allItems[allItems.length - 1] as HTMLElement;
      const beforeRigthEl = allItems[allItems.length - 3] as HTMLElement;
      const mostLeftEl = itemsParent.querySelector(".item");
      if (mostRigthEl && mostLeftEl) {
        const newItem = beforeRigthEl.cloneNode(true) as HTMLElement;
        newItem.querySelectorAll("img").forEach((img) => {
          img.ondragstart = (e) => e.preventDefault();
        });
        newItem.classList.add("hide");
        itemsParent.prepend(newItem);
        setTimeout(() => {
          newItem.classList.remove("hide");
        }, 0);

        setTimeout(() => {
          const allItems = itemsParent.querySelectorAll(".item:not(.hide)");
          const mostRigthEl = allItems[allItems.length - 1] as HTMLElement;
          itemsParent.removeChild(mostRigthEl);
        }, 0);
      }
    }
  };

  const moveLeft = () => {
    const itemsParent = itemsRef.current as HTMLElement;
    if (itemsParent) {
      const allItems = Array.from(itemsParent.querySelectorAll(".item"));
      const mostRightEl = allItems[allItems.length - 1];
      const mostLeftEl = itemsParent.querySelector(
        ".item:not(.hide)"
      ) as HTMLElement;
      if (mostRightEl && mostLeftEl) {
        const mostLeftElIndex = allItems.findIndex(
          (item) => item === mostLeftEl
        );
        const afterLeftEl = allItems[mostLeftElIndex + 2] as HTMLElement;
        mostLeftEl.classList.add("hide");
        const newItem = afterLeftEl.cloneNode(true) as HTMLElement;
        newItem.querySelectorAll("img").forEach((img) => {
          img.ondragstart = (e) => e.preventDefault();
        });
        itemsParent.appendChild(newItem);

        setTimeout(
          () => {
            itemsParent.removeChild(mostLeftEl);
          },
          auto && !stopAuto ? auto.animTimeS * 1000 : animTime * 1000
        );
      }
    }
  };

  const leftClick = () => {
    let newIndex = curMediaIndex;
    if (!infinite) {
      if (curMediaIndex - 1 < 0) {
        newIndex = 0;
      } else {
        newIndex = curMediaIndex - 1;
      }
    } else if (curMediaIndex - 1 < 0) {
      newIndex = items.length - 1;
    } else {
      newIndex = curMediaIndex - 1;
    }
    setCurMediaIndex(newIndex);
  };

  const rightClick = () => {
    let newIndex = curMediaIndex;
    if (!infinite) {
      if (curMediaIndex < itemsCount - 1 - (showCount - 1)) {
        newIndex = curMediaIndex + 1;
      } else {
        newIndex = itemsCount - 1 - (showCount - 1);
      }
    } else if (curMediaIndex >= items.length - 1) {
      newIndex = 0;
    } else {
      newIndex = curMediaIndex + 1;
    }
    setCurMediaIndex(newIndex);
  };

  function shortestPath(array: any, start: any, end: any) {
    const startIndex = start;
    const endIndex = end;
    if (startIndex === -1 || endIndex === -1) {
      return "Один или оба элемента не найдены в массиве";
    }
    const directPath =
      startIndex < endIndex
        ? array.slice(startIndex, endIndex + 1)
        : array.slice(endIndex, startIndex + 1).reverse();
    const wrapAroundPath1 = array
      .slice(startIndex)
      .concat(array.slice(0, endIndex + 1));
    const wrapAroundPath2 = array
      .slice(endIndex)
      .concat(array.slice(0, startIndex + 1))
      .reverse();
    const shortestPath = [directPath, wrapAroundPath1, wrapAroundPath2].reduce(
      (shortest, current) => {
        return current.length < shortest.length ? current : shortest;
      }
    );
    return shortestPath;
  }

  useEffect(() => {
    const itemsRefHTML = itemsRef.current as HTMLElement;
    if (!infinite && itemsRefHTML) {
      handleDotClick(curMediaIndex);
    } else {
      if (prevIndex === null) {
        setPrevIndex(curMediaIndex);
      } else {
        let add = 0;
        let shortest = shortestPath(items, prevIndex, curMediaIndex);
        const valIn = () => {
          for (let index in items.slice(Math.ceil(items.length / 2))) {
            if (
              shortest.find(
                (el: any) =>
                  el === items[Number(index) + Math.ceil(items.length / 2)]
              )
            ) {
              return true;
            }
          }
          return false;
        };
        let curIndx = curMediaIndex;
        if (shortest.find((el: any) => el === items[0]) && valIn()) {
          if (items.indexOf(shortest[0]) >= Math.ceil(items.length / 2)) {
            add += items.length + curIndx;
            curIndx = 0;
          } else if (
            items.indexOf(shortest[0]) <= Math.ceil(items.length / 2)
          ) {
            curIndx = 0;
            add -= shortest.length - 1 - items.indexOf(shortest[0]);
          }
        }
        let _prev = prevIndex;
        while (_prev !== curIndx + add) {
          if (_prev < curIndx + add) {
            moveLeft();
            _prev += 1;
          } else if (_prev > curIndx + add) {
            moveRight();
            _prev -= 1;
          }
        }
        setPrevIndex(curMediaIndex);
      }
    }
    itemsRefHTML.dataset.transformx = "0";
    itemsRefHTML.style.transition = `all ${animTime}s ease-in-out`;
    if (infinite) {
      itemsRefHTML.style.transform = `translateX(-${
        itemWidth * (items.length + 1)
      }px)`;
    }
  }, [curMediaIndex]);

  function onDragEnd(e: any) {
    if (winW <= scrollLockPoint) document.body.style.overflowY = "scroll";
    const mediaBlock = itemsRef.current as HTMLElement;
    let newIndex = curMediaIndex;
    if (startX > 0 && isDragging) {
      const difference = Number(mediaBlock.dataset.transformx);
      if (difference < -trashHold) {
        newIndex += 1;
        if (!infinite) {
          if (curMediaIndex < itemsCount - 1 - (showCount - 1)) {
            rightClick();
          }
        } else {
          rightClick();
        }
      } else if (difference > trashHold) {
        newIndex -= 1;
        if (!infinite) {
          if (curMediaIndex > 0) {
            leftClick();
          }
        } else {
          leftClick();
        }
      } else {
        if (Date.now() - lastActionDate < 200) {
          if (itemClick) {
            itemClick(curMediaIndex);
          }
        }
      }
    }

    if (curMediaIndex === newIndex) {
      mediaBlock.dataset.transformx = "0";
      mediaBlock.style.transition = `all ${animTime}s ease-in-out`;
      if (infinite) {
        mediaBlock.style.transform = `translateX(-${
          itemWidth * (items.length + 1)
        }px)`;
      } else {
        mediaBlock.style.transform = `translateX(-${getItemsWidthSum(
          curMediaIndex
        )}px)`;
      }
    }

    setIsDragging(false);
  }

  function onDragStart(e: any) {
    if (winW <= scrollLockPoint) document.body.style.overflowY = "hidden";
    setStartX(getPosition(e, "X"));
    setIsDragging(true);
    setLastActionDate(Date.now());
    if (auto?.autoStopAfter === "any interaction") {
      setStopAuto(true);
    }
    const mediaBlock = itemsRef.current as HTMLElement;
    mediaBlock.style.transition = "unset";
    if (auto?.autoStopAfter === "any interaction") {
      setStopAuto(true);
    }
  }

  function onDragMove(e: any) {
    const currentPosition = getPosition(e, "X");
    const difference = startX - currentPosition;
    const mediaBlock = itemsRef.current as HTMLElement;
    const transform = -difference - getItemsWidthSum(curMediaIndex);

    if (isDragging) {
      const itemsParent = itemsRef.current as HTMLElement;
      if (infinite) {
        itemsParent.style.transform = `translateX(${
          -itemWidth * (items.length + 1) + difference * -1
        }px)`;
      } else {
        if (
          transform < 0 &&
          transform > -getItemsWidthSum(itemsCount - showCount)
        ) {
          itemsParent.style.transform = `translateX(${
            -difference - getItemsWidthSum(curMediaIndex)
          }px)`;
        }
      }

      mediaBlock.dataset.transformx = String(Number(-difference));
    }
  }

  useEffect(() => {
    document.body.addEventListener("touchend", onDragEnd);
    document.body.addEventListener("mouseup", onDragEnd);
    return () => {
      document.body.removeEventListener("mouseup", onDragEnd);
      document.body.removeEventListener("touchend", onDragEnd);
    };
  }, [isDragging, curMediaIndex]);

  const handleDotClick = (index: number) => {
    setCurMediaIndex(index);
    const mediaBlock = itemsRef.current as HTMLElement;
    mediaBlock.style.transform = `translateX(-${getItemsWidthSum(index)}px)`;
  };

  return (
    <div
      style={
        itemWidth
          ? {
              width: itemWidth * showCount + "px",
            }
          : {}
      }
      ref={ref}
      className={`horizontal-scroll ${className || ""}`}
    >
      <div
        onClick={() => {
          if (auto?.autoStopAfter === "any interaction") {
            setStopAuto(true);
          }
          leftClick();
        }}
        className={`arrow l ${!arrows && "hide"}`}
      >
        {LeftArrowSVG || (
          <svg
            viewBox="0 0 18 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.5207 0.468629C18.1598 1.09347 18.1598 2.10653 17.5207 2.73137L3.95053 16L17.5207 29.2686C18.1598 29.8935 18.1598 30.9065 17.5207 31.5314C16.8817 32.1562 15.8456 32.1562 15.2066 31.5314L0.47928 17.1314C-0.15976 16.5065 -0.15976 15.4935 0.47928 14.8686L15.2066 0.468629C15.8456 -0.15621 16.8817 -0.15621 17.5207 0.468629Z"
              fill="#B5ABA6"
            />
          </svg>
        )}
      </div>
      <div className={`shadow-box l ${!shadows && "hide"}`}></div>
      <div className="items-parent">
        <div
          data-transformx={0}
          className={`items ${isDragging ? "dragging" : ""}`}
          ref={itemsRef}
          onMouseDown={onDragStart}
          onMouseMove={onDragMove}
          onTouchStart={onDragStart}
          onTouchMove={onDragMove}
        >
          {(infinite
            ? [items[items.length - 1], ...items, ...items, items[0]]
            : items
          ).map((item, index: number) => (
            <div
              style={{
                width: itemWidth + "px",
              }}
              className="item"
              id={String(index)}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
      </div>

      <div className={`shadow-box r ${!shadows && "hide"}`}></div>
      <div
        onClick={() => {
          if (auto?.autoStopAfter === "any interaction") {
            setStopAuto(true);
          }
          rightClick();
        }}
        className={`arrow r ${!arrows && "hide"}`}
      >
        {RightArrowSVG || (
          <svg
            viewBox="0 0 18 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.47928 0.468629C-0.15976 1.09347 -0.15976 2.10653 0.47928 2.73137L14.0495 16L0.47928 29.2686C-0.15976 29.8935 -0.15976 30.9065 0.47928 31.5314C1.11832 32.1562 2.15441 32.1562 2.79345 31.5314L17.5207 17.1314C18.1598 16.5065 18.1598 15.4935 17.5207 14.8686L2.79345 0.468629C2.15441 -0.15621 1.11832 -0.15621 0.47928 0.468629Z"
              fill="#B5ABA6"
            />
          </svg>
        )}
      </div>
      <div className={`dots-parent ${!dots && "hide"}`}>
        <div className="dots">
          {items.length > 1 &&
            Array.from({
              length: infinite ? items.length : items.length - (showCount - 1),
            }).map((_, index: number) => (
              <div
                key={index}
                onClick={(e: any) => {
                  if (auto?.autoStopAfter === "any interaction") {
                    setStopAuto(true);
                  }
                  handleDotClick(index);
                }}
                className={`dot ${index === curMediaIndex ? "dot-active" : ""}`}
              ></div>
            ))}
        </div>
      </div>
    </div>
  );
};
