import { CompanyType, NamedObj, Size } from "./interfaces";

export interface ActionType {
  type: string;
  data: any;
}



export const setCompany = (company: CompanyType) => ({
  type: "setCompany",
  data: { company: company },
});
export const setApartmentTypes = (apartmentTypes: NamedObj[]) => ({
  type: "setApartmentTypes",
  data: { apartmentTypes: apartmentTypes },
});
export const setWinSize = (winSize: Size) => ({
  type: "setWinSize",
  data: { winSize: winSize },
});
