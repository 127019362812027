import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./BaseHeader.scss";
import { useScrollHide } from "../../Hooks/useScrollHide";

export interface BaseHeaderProps {
  scrollHide?: boolean;
  children: ReactNode;
  className?: string;
  reference?: React.RefObject<HTMLDivElement>;
  fixed?: boolean;
}

export const BaseHeader: FC<BaseHeaderProps> = ({
  children,
  scrollHide,
  className,
  reference,
  fixed,
}) => {
  const headerRef = useRef(null);
  const ref = reference || headerRef;
  const hide = useScrollHide();

  useEffect(() => {
    if (ref.current) {
      const height = window.getComputedStyle(ref.current as HTMLElement).height;
      document.body.style.marginTop = height;
    }
  }, [ref.current]);

  return (
    <header
      ref={ref}
      className={`${className || ""} ${fixed ? "fixed" : ""} ${
        hide && scrollHide ? "hide" : ""
      }`}
    >
      {children}
    </header>
  );
};
