import { useEffect, useState } from "react";
import "./ApartmentCard.scss";
import { ApartmentType, InitialState, JK } from "../../../redux/interfaces";
import { useSelector } from "react-redux";
import JKModal, { JKModalProps } from "../../Jk/JKModal";
import { formatPrice, formatPriceSpaces } from "../../../utils";
import ClipboardJS from "clipboard";
import { SERVER_DOMAIN } from "../../../App";

type ApartmentCardProps = {
  apartment: ApartmentType;
  jk: JK;
  solo?: boolean;
};

const ApartmentCard: React.FC<ApartmentCardProps> = ({
  apartment,
  jk,
  solo,
}) => {
  const company = useSelector((state: InitialState) => state.company);
  const [selectedApartment, setSelectedApartment] =
    useState<JKModalProps | null>(null);
  const [open, setOpen] = useState<boolean | undefined>(solo);
  const onToggleClick = () => {
    setOpen(!open);
  };
  const [winWidth, setWinWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      const winWidth = window.innerWidth;
      setWinWidth(winWidth);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [winWidth]);

  const btns = (
    <div className="btns">
      <button
        onClick={() =>
          setSelectedApartment({
            theme: "Хочу забронировать квартиру в ЖК",
            jk: jk,
            apartment: apartment,
            title: "Забронировать квартиру",
          })
        }
      >
        Забронировать квартиру
      </button>
      <button
        onClick={() =>
          setSelectedApartment({
            theme: "Хочу получить расчет ипотеки для квартиры в ЖК",
            jk: jk,
            apartment: apartment,
            title: "Рассчитать ипотеку",
          })
        }
      >
        Рассчитать ипотеку
      </button>
      <button
        onClick={() =>
          setSelectedApartment({
            theme: "Хочу получить консультацию по квартире в ЖК",
            jk: jk,
            apartment: apartment,
            title: "Получить консультацию",
          })
        }
      >
        Получить консультацию
      </button>
    </div>
  );

  new ClipboardJS(".copy");

  const onCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    const button = e.target as HTMLButtonElement;
    button.style.opacity = "0.5";

    setTimeout(() => {
      button.style.opacity = "1";
    }, 500);
  };

  return (
    <div
      className={`ApartmentCard ${open ? "opened" : "closed"} ${
        solo ? "solo" : ""
      }`}
    >
      {selectedApartment && (
        <JKModal
          {...selectedApartment}
          onClose={() => setSelectedApartment(null)}
        />
      )}
      <div onClick={onToggleClick} className="closed">
        <p>{apartment.room_size.name}</p>
        <p>
          <span>{winWidth > 600 ? "Общая площадь" : "Об. S"}&nbsp;&nbsp;</span>
          {apartment.area_total} м²
        </p>
        <p>
          <span>Жилая площадь&nbsp;&nbsp;</span>
          {apartment.area_rooms_total} м²
        </p>
        <p>
          <span>Этаж&nbsp;&nbsp;</span>
          {apartment.floor}
        </p>
        <p>
          <span>Стоимость&nbsp;&nbsp;</span>
          {formatPrice(apartment.price)} ₽
        </p>
        <div className="arrow">
          <svg
            viewBox="0 0 28 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2L14 14L26 2"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="opened">
        {!solo && (
          <div onClick={onToggleClick} className="arrow">
            <svg
              viewBox="0 0 28 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L14 14L26 2"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}

        <div className="img-box">
          <img src={apartment.plans[0].src} alt="" />
          {btns}
        </div>
        <div className="table-parent">
          <table>
            <tr>
              <td>Тип квартиры:</td>
              <td>{apartment.room_size.name}</td>
            </tr>
            <tr>
              <td>Общая площадь:</td>
              <td>{apartment.area_total} м²</td>
            </tr>
            <tr>
              <td>Жилая площадь:</td>
              <td>{apartment.area_rooms_total} м²</td>
            </tr>
            <tr>
              <td>Площадь кухни:</td>
              <td>{apartment.area_kitchen} м²</td>
            </tr>
            <tr>
              <td>Площадь комнат:</td>
              <td>
                {apartment.area_rooms
                  .replaceAll(/\.\d+/g, "")
                  .replaceAll("+", "м² / ")}
                м²
              </td>
            </tr>
            <tr>
              <td>Общая площадь комнат:</td>
              <td>{apartment.area_rooms_total} м²</td>
            </tr>
            <tr>
              <td>Высота потолка:</td>
              <td>{apartment.height} м</td>
            </tr>
            <tr>
              <td>Этаж:</td>
              <td>{apartment.floor}</td>
            </tr>
            <tr>
              <td>Кол-во санузлов:</td>
              <td>{apartment.wc_count}</td>
            </tr>
            <tr>
              <td>Отделка:</td>
              <td>{apartment.finishing.name}</td>
            </tr>
          </table>
          <h1 className="price">{formatPriceSpaces(apartment.price)} ₽</h1>
          {btns}
          <div className="icons">
            <div
              onClick={(e: any) => onCopy(e)}
              data-clipboard-text={`${SERVER_DOMAIN}/blocks/${jk.id}/apartment/${apartment.id}/`}
              className="copy"
            >
              <svg
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="1"
                  width="38"
                  height="38"
                  rx="7"
                  stroke="#807EF2"
                  stroke-width="2"
                />
                <path
                  d="M11 24.7V12.1C11 10.9402 11.8954 10 13 10H23M17 31H26C27.1046 31 28 30.0598 28 28.9V16.3C28 15.1402 27.1046 14.2 26 14.2H17C15.8954 14.2 15 15.1402 15 16.3V28.9C15 30.0598 15.8954 31 17 31Z"
                  stroke="#807EF2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div onClick={() => window.print()} className="print">
              <svg
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="1"
                  width="38"
                  height="38"
                  rx="7"
                  stroke="#807EF2"
                  stroke-width="2"
                />
                <path
                  d="M23.375 29V23.375H16.625V29M26.75 29H13.25C12.0074 29 11 27.9927 11 26.75V13.25C11 12.0074 12.0074 11 13.25 11H22.4431C23.0398 11 23.612 11.237 24.034 11.659L28.341 15.966C28.763 16.388 29 16.9602 29 17.557V26.75C29 27.9927 27.9927 29 26.75 29Z"
                  stroke="#807EF2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="mail">
              <a href={`mailto:${company?.email}`}>
                <svg
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="1"
                    width="38"
                    height="38"
                    rx="7"
                    stroke="#807EF2"
                    stroke-width="2"
                  />
                  <path
                    d="M24.8889 20C24.8889 22.7 22.7 24.8889 20 24.8889C17.2999 24.8889 15.1111 22.7 15.1111 20C15.1111 17.2999 17.2999 15.1111 20 15.1111C22.7 15.1111 24.8889 17.2999 24.8889 20ZM24.8889 20V21.8333C24.8889 23.5209 26.2569 24.8889 27.9444 24.8889C29.632 24.8889 31 23.5209 31 21.8333V20C31 13.9249 26.0752 9 20 9C13.9249 9 9 13.9249 9 20C9 26.0752 13.9249 31 20 31H24.8889"
                    stroke="#807EF2"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentCard;
