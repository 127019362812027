import { useState, useEffect } from "react";

type useScrollHideProps = {
  scrollBlockSelector?: string;
};

export const useScrollHide = ({
  scrollBlockSelector = "",
}: useScrollHideProps = {}) => {
  const [hide, setHide] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const scrollBlock = scrollBlockSelector
      ? document.querySelector(scrollBlockSelector)
      : window;
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // Определяем направление прокрутки
      if (scrollTop > lastScrollTop) {
        // Прокрутка вниз
        setHide(true);
      } else {
        // Прокрутка вверх
        setHide(false);
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // Учитываем прокрутку влево/вправо
    };

    if (scrollBlock) {
      scrollBlock.addEventListener("scroll", handleScroll);

      return () => {
        scrollBlock.removeEventListener("scroll", handleScroll);
      };
    } else {
        console.warn(
          `scrollBlock with selector ${scrollBlockSelector} was not found`
        );
    }
  }, [lastScrollTop]);

  return hide;
};
