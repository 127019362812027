import { ReactNode, useEffect, useState } from "react";

type ScrollPaginationProps = {
  page: number;
  setPage: (value: number) => void;
  scrollBoxSelector?: string;
  children: ReactNode;
  loading: boolean;
  hasMore: boolean;
  setLoading: (value: boolean) => void;
  delay?: number;
};

export const ScrollPagination: React.FC<ScrollPaginationProps> = ({
  page,
  scrollBoxSelector,
  setPage,
  children,
  hasMore,
  loading,
  setLoading,
  delay = 2000,
}) => {
  const waitForElement = (
    selector: string,
    callback: (element: HTMLElement) => void
  ) => {
    const element = document.querySelector<HTMLElement>(selector);
    if (element) {
      callback(element);
    } else {
      setTimeout(() => waitForElement(selector, callback), 100);
    }
  };

  useEffect(() => {
    const handleScroll = (el: HTMLElement) => {
      if (
        window.innerHeight + window.scrollY >=
          el.offsetTop + el.clientHeight - delay &&
        !loading &&
        hasMore
      ) {
        console.log(page);
        setLoading(true);
        setPage(page + 1);
      }
    };
    if (scrollBoxSelector) {
      waitForElement(scrollBoxSelector, (el: HTMLElement) => {
        el.addEventListener("scroll", () => handleScroll(el));
      });

      return () => {
        waitForElement(scrollBoxSelector, (el: HTMLElement) => {
          el.removeEventListener("scroll", () => handleScroll(el));
        });
      };
    } else {
      window.addEventListener("scroll", () => handleScroll(document.body));
      return () => {
        window.removeEventListener("scroll", () => handleScroll(document.body));
      };
    }
  }, [scrollBoxSelector, loading]);

  return <>{children}</>;
};
